import styled from 'styled-components';
import { Form } from 'antd';

interface ContainerProps {
    readonly mobile: boolean;
}

export const Container = styled.div<ContainerProps>`
    padding: ${props => (props.mobile ? '32px 16px' : '20px 0px 30px 0px')};
    display: flex;
    flex-direction: ${props => (props.mobile ? 'column' : 'row')};

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-form-item-label {
        padding-bottom: 2px;
    }

    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select-selector {
        border-radius: 2px !important;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 50px;
`;

export const Section = styled.div<ContainerProps>`
    margin-bottom: 28px;
    ${props => (props.mobile ? 'width: 320px' : '')};
`;

export const ResultContent = styled.div`
    display: flex;
    width: auto;
`;

export const ResultData = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    background: #f0f2f5;
    border-radius: 4px;
`;

interface FormRowProps {
    readonly mobile: boolean;
}

export const FormRow = styled.div<FormRowProps>`
    display: flex;
    align-items: flex-end;
    gap: ${props => (props.mobile ? '0px' : '16px')};
    flex-direction: ${props => (props.mobile ? 'column' : 'row')};
    align-items: ${props => (props.mobile ? 'stretch' : 'flex-end')};
`;

export const NameInput = styled(Form.Item as any)`
    width: 320px;
`;
