import styled from 'styled-components';

export const StyledFooter = styled.div`
    min-height: 24px;
    width: 100%;
    margin-left: -48px;
    display: flex;
    justify-content: center;
    z-index: 1;
    position: fixed;
    bottom: 0;
    background: #fff;

    @media (min-width: 576px) and (max-width: 991px) {
        width: 100%;
        height: 80px;
        padding: 0 48px 0 48px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        border-top: 2px;
        border-top-style: solid;
        border-top-color: #d9d9d9;
    }

    @media (min-width: 992px) {
        width: 80%;
        height: 80px;
        padding: 0 48px 0 48px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        border-top: 2px;
        border-top-style: solid;
        border-top-color: #d9d9d9;
    }

    @media (min-width: 1370px) {
        width: 100%;
        padding-right: 20%;
    }
`;

export const StyledDesktopButtons = styled.div`
    display: flex;
    flex-wrap: wrap;

    > button {
        margin-top: 5px;

        &:not(:last-child) {
            margin-right: 8px;
        }

        &:first-child {
            svg {
                margin-right: 5px;
            }
        }

        &:not(:first-child) {
            svg {
                margin-left: 5px;
            }
        }
    }
`;
