import React, { useState } from 'react';
import { Button, Form, Input, message, Modal, Grid } from 'antd';
import { useStore } from 'hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Container, Content, InputContainer, Section } from './styles';
import { SectionTitle } from '../../Settings/Profile/styles';

const { useBreakpoint } = Grid;

const ApplicationDeleteForm = (): React.ReactElement => {
    const { store } = useStore();
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [applicationId, setApplicationId] = useState<string | null>(null);
    const screen = useBreakpoint();

    const handleClear = () => {
        form.resetFields();
        setApplicationId(null);
    };

    const handleDeleteApplication = () => {
        if (applicationId) {
            setLoading(true);
            store.applicationDeleteStore
                .deleteApplication({ id: applicationId })
                .then(() => {
                    handleClear();
                    message.success(
                        formatMessage({
                            id: 'app.application.delete.form.success',
                            defaultMessage: 'Aplicação excluída com sucesso.',
                        }),
                    );
                })
                .catch(err => {
                    if (err.response) message.error(err.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const showDeleteConfirm = () => {
        if (applicationId) {
            Modal.confirm({
                title: 'Tem certeza que deseja excluir a aplicação?',
                icon: <ExclamationCircleOutlined />,
                content: `ID: ${applicationId}`,
                okText: formatMessage({
                    id: 'app.confirm.dialog.yes',
                    defaultMessage: 'Sim',
                }),
                okType: 'danger',
                cancelText: formatMessage({
                    id: 'app.confirm.dialog.no',
                    defaultMessage: 'Não',
                }),
                onOk() {
                    handleDeleteApplication();
                },
            });
        }
    };

    return (
        <Container mobile={!screen.md}>
            <Content>
                <Section mobile={!screen.md} style={{ marginTop: 8 }}>
                    <SectionTitle>
                        <FormattedMessage
                            id="app.application.delete.form.title"
                            defaultMessage="Excluir Aplicação"
                        />
                    </SectionTitle>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={showDeleteConfirm}
                    >
                        <InputContainer>
                            <Form.Item
                                name="id"
                                label={
                                    <FormattedMessage
                                        id="app.application.delete.id"
                                        defaultMessage="ID da Aplicação"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: formatMessage({
                                            id: 'app.application.delete.id.required',
                                            defaultMessage:
                                                'Por favor, insira o ID da aplicação.',
                                        }),
                                    },
                                ]}
                            >
                                <Input.Search
                                    placeholder="Ex: 123"
                                    onChange={e =>
                                        setApplicationId(e.target.value)
                                    }
                                    allowClear
                                    enterButton={
                                        <Button
                                            type="primary"
                                            danger
                                            onClick={() => form.submit()}
                                            loading={loading}
                                            disabled={!applicationId}
                                        >
                                            <FormattedMessage
                                                id="app.application.delete.submit"
                                                defaultMessage="Excluir"
                                            />
                                        </Button>
                                    }
                                />
                            </Form.Item>
                        </InputContainer>
                    </Form>
                </Section>
            </Content>
        </Container>
    );
};

export default ApplicationDeleteForm;
