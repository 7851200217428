/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import isAdmin from '../helpers/auth';
import { validateToken } from '../helpers/routeHelpers';
import { useStore } from '../hooks';

interface AdminRouteProps extends Omit<RouteProps, 'render'> {
    component: React.ComponentType<any>;
    layout: React.ComponentType<any>;
    titleId: string;
    exact?: boolean;
}

const AdminRoute: React.FC<AdminRouteProps> = ({
    component: Component,
    layout: Layout,
    titleId,
    path,
    exact,
}) => {
    const location = useLocation();
    const { store } = useStore();

    useEffect(() => {
        store.uiStateStore.setNavBarTitleForMobile(titleId);
    }, [store.uiStateStore, titleId, location.pathname]);

    const { isValid: isAuthenticated } = validateToken();

    if (!isAuthenticated) {
        const lastUrl = location.pathname + location.search;
        return <Redirect to={{ pathname: '/login', state: { lastUrl } }} />;
    }

    if (!isAdmin()) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <Route
            path={path}
            exact={exact}
            render={props => (
                <Layout>
                    <Component path={props.location.pathname} />
                </Layout>
            )}
        />
    );
};

AdminRoute.defaultProps = {
    exact: false,
};

export default observer(AdminRoute);
