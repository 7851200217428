import React, { useEffect, useState, useCallback } from 'react';
import {
    Table,
    Button,
    message,
    Modal,
    Tag,
    Input,
    Space,
    Tooltip,
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    ExclamationCircleOutlined,
    SearchOutlined,
    DeleteOutlined,
    UserOutlined,
    CrownOutlined,
    UserDeleteOutlined,
} from '@ant-design/icons';
import { observer } from 'mobx-react';
import { Container, Content, Section } from './styles';
import { SectionTitle } from '../../Settings/Profile/styles';
import { useStore } from '../../../../hooks';
import { User } from '../../../../services/userService';
import UsersListFooter from '../ListFooter';

const UserListForm: React.FC = () => {
    const { store } = useStore();
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [searchText, setSearchText] = useState('');

    const sortUsersByAdmin = (userList: User[]): User[] => {
        return [...userList].sort((a, b) => {
            const aIsAdmin = a.roles.includes('admin');
            const bIsAdmin = b.roles.includes('admin');

            if (aIsAdmin && !bIsAdmin) return -1;
            if (!aIsAdmin && bIsAdmin) return 1;
            return 0;
        });
    };

    const fetchUsers = useCallback(async () => {
        setLoading(true);
        try {
            const data = await store.userListStore.listUsers();
            const sortedData = sortUsersByAdmin(data || []);
            setUsers(sortedData);
            setFilteredUsers(sortedData);
        } catch (error) {
            message.error(
                formatMessage({
                    id: 'app.user.list.error',
                    defaultMessage: 'Erro ao carregar usuários.',
                }),
            );
        } finally {
            setLoading(false);
        }
    }, [formatMessage, store.userListStore]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        if (!searchText) {
            setFilteredUsers(users);
            return;
        }

        const lowerSearchText = searchText.toLowerCase();
        const filtered = users.filter(
            user =>
                user.name.toLowerCase().includes(lowerSearchText) ||
                user.email.toLowerCase().includes(lowerSearchText) ||
                (user.cpf && user.cpf.includes(searchText)),
        );

        const sortedFiltered = sortUsersByAdmin(filtered);
        setFilteredUsers(sortedFiltered);
    }, [searchText, users]);

    const handlePromote = async (userId: string) => {
        try {
            await store.userListStore.promoteToAdmin({ id: userId });
            message.success(
                formatMessage({
                    id: 'app.user.promote.success',
                    defaultMessage: 'Usuário promovido com sucesso.',
                }),
            );
            fetchUsers();
        } catch (error) {
            message.error(
                (error as any)?.response?.data?.message ||
                    formatMessage({
                        id: 'app.user.promote.error',
                        defaultMessage: 'Erro ao promover usuário.',
                    }),
            );
        }
    };

    const handleDemote = async (userId: string) => {
        try {
            await store.userListStore.demoteFromAdmin({ id: userId });
            message.success(
                formatMessage({
                    id: 'app.user.demote.success',
                    defaultMessage: 'Permissão de admin removida com sucesso.',
                }),
            );
            fetchUsers();
        } catch (error) {
            message.error(
                (error as any)?.response?.data?.message ||
                    formatMessage({
                        id: 'app.user.demote.error',
                        defaultMessage: 'Erro ao remover permissão de admin.',
                    }),
            );
        }
    };

    const handleDelete = async (userId: string) => {
        try {
            await store.userListStore.deleteUser({ id: userId });
            message.success(
                formatMessage({
                    id: 'app.user.delete.success',
                    defaultMessage: 'Usuário excluído com sucesso.',
                }),
            );
            fetchUsers();
        } catch (error) {
            message.error(
                (error as any)?.response?.data?.message ||
                    formatMessage({
                        id: 'app.user.delete.error',
                        defaultMessage: 'Erro ao excluir usuário.',
                    }),
            );
        }
    };

    const showPromoteConfirm = (id: string, name: string) => {
        Modal.confirm({
            title: formatMessage({
                id: 'app.user.promote.confirm.title',
                defaultMessage: 'Tem certeza que deseja promover este usuário?',
            }),
            icon: <ExclamationCircleOutlined />,
            content: (
                <span>
                    {formatMessage(
                        {
                            id: 'app.user.promote.confirm.content',
                            defaultMessage:
                                'O usuário {name} será promovido a administrador.',
                        },
                        { name },
                    )}
                </span>
            ),
            okText: formatMessage({
                id: 'app.confirm.dialog.yes',
                defaultMessage: 'Sim',
            }),
            okType: 'primary',
            cancelText: formatMessage({
                id: 'app.confirm.dialog.no',
                defaultMessage: 'Não',
            }),
            onOk() {
                handlePromote(id);
            },
        });
    };

    const showDemoteConfirm = (id: string, name: string) => {
        Modal.confirm({
            title: formatMessage({
                id: 'app.user.demote.confirm.title',
                defaultMessage:
                    'Tem certeza que deseja remover as permissões de administrador?',
            }),
            icon: <ExclamationCircleOutlined />,
            content: (
                <span>
                    {formatMessage(
                        {
                            id: 'app.user.demote.confirm.content',
                            defaultMessage:
                                'As permissões de administrador serão removidas do usuário {name}.',
                        },
                        { name },
                    )}
                </span>
            ),
            okText: formatMessage({
                id: 'app.confirm.dialog.yes',
                defaultMessage: 'Sim',
            }),
            okType: 'danger',
            cancelText: formatMessage({
                id: 'app.confirm.dialog.no',
                defaultMessage: 'Não',
            }),
            onOk() {
                handleDemote(id);
            },
        });
    };

    const showDeleteConfirm = (id: string, name: string) => {
        Modal.confirm({
            title: formatMessage({
                id: 'app.user.delete.confirm.title',
                defaultMessage: 'Tem certeza que deseja excluir este usuário?',
            }),
            icon: <ExclamationCircleOutlined />,
            content: (
                <span>
                    {formatMessage(
                        {
                            id: 'app.user.delete.confirm.content',
                            defaultMessage:
                                'O usuário {name} será excluído permanentemente.',
                        },
                        { name },
                    )}
                </span>
            ),
            okText: formatMessage({
                id: 'app.confirm.dialog.yes',
                defaultMessage: 'Sim',
            }),
            okType: 'danger',
            cancelText: formatMessage({
                id: 'app.confirm.dialog.no',
                defaultMessage: 'Não',
            }),
            onOk() {
                handleDelete(id);
            },
        });
    };

    const isAdmin = (user: User): boolean => {
        return user.roles.includes('admin');
    };

    const columns = [
        {
            title: (
                <FormattedMessage
                    id="app.user.list.table.name"
                    defaultMessage="Nome"
                />
            ),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: (
                <FormattedMessage
                    id="app.user.list.table.email"
                    defaultMessage="Email"
                />
            ),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'CPF',
            dataIndex: 'cpf',
            key: 'cpf',
        },
        {
            title: (
                <FormattedMessage
                    id="app.user.list.table.role"
                    defaultMessage="Função"
                />
            ),
            dataIndex: 'roles',
            key: 'roles',
            render: (roles: string[]) => (
                <>
                    {roles.map(role => (
                        <Tag
                            key={role}
                            color={role === 'admin' ? 'blue' : 'green'}
                        >
                            {role === 'admin' ? 'Administrador' : 'Usuário'}
                        </Tag>
                    ))}
                </>
            ),
        },
        {
            title: (
                <FormattedMessage
                    id="app.user.list.table.actions"
                    defaultMessage="Ações"
                />
            ),
            key: 'actions',
            render: (_: any, record: User) => (
                <Space>
                    {!isAdmin(record) ? (
                        <Tooltip
                            title={formatMessage({
                                id: 'app.user.list.table.promote.tooltip',
                                defaultMessage: 'Promover a administrador',
                            })}
                        >
                            <Button
                                type="text"
                                icon={
                                    <span
                                        style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                        }}
                                    >
                                        <UserOutlined
                                            style={{
                                                color: '#1890ff',
                                                fontSize: '16px',
                                            }}
                                        />
                                        <CrownOutlined
                                            style={{
                                                color: '#1890ff',
                                                fontSize: '12px',
                                                position: 'absolute',
                                                right: -7,
                                                top: -5,
                                            }}
                                        />
                                    </span>
                                }
                                onClick={() =>
                                    showPromoteConfirm(record.id, record.name)
                                }
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip
                            title={formatMessage({
                                id: 'app.user.list.table.demote.tooltip',
                                defaultMessage:
                                    'Remover permissão de administrador',
                            })}
                        >
                            <Button
                                type="text"
                                icon={
                                    <UserDeleteOutlined
                                        style={{ color: '#faad14' }}
                                    />
                                }
                                onClick={() =>
                                    showDemoteConfirm(record.id, record.name)
                                }
                            />
                        </Tooltip>
                    )}
                    <Tooltip
                        title={formatMessage({
                            id: 'app.user.list.table.delete.tooltip',
                            defaultMessage: 'Excluir usuário',
                        })}
                    >
                        <Button
                            type="text"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() =>
                                showDeleteConfirm(record.id, record.name)
                            }
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <Container mobile={false}>
            <Content>
                <Section mobile={false} style={{ marginTop: 8 }}>
                    <SectionTitle>
                        <FormattedMessage
                            id="app.user.list.table.title"
                            defaultMessage="Controle de Usuários"
                        />
                    </SectionTitle>
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ width: '100%', marginBottom: 16 }}
                    >
                        <Input
                            placeholder={formatMessage({
                                id: 'app.users.search.placeholder',
                                defaultMessage:
                                    'Buscar por nome, e-mail ou CPF',
                            })}
                            prefix={<SearchOutlined />}
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            allowClear
                            style={{ width: '320px' }}
                        />
                        <Table
                            dataSource={filteredUsers}
                            columns={columns}
                            loading={loading}
                            rowKey="id"
                            pagination={{
                                pageSize: 10,
                                showTotal: (total, range) =>
                                    `${range[0]}-${range[1]} de ${total} usuários`,
                            }}
                        />
                    </Space>
                </Section>
            </Content>
            <UsersListFooter onRefresh={fetchUsers} loading={loading} />
        </Container>
    );
};

export default observer(UserListForm);
