/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable, makeObservable } from 'mobx';

export default class SubjectEnrollStore {
    @observable formData;

    @observable selfie;

    @observable docPhoto;

    @observable enrollResponse;

    rootStore;

    transportLayer;

    // eslint-disable-next-line
    constructor(rootStore, transportLayer) {
        this.rootStore = rootStore;
        makeObservable(this);
        this.transportLayer = transportLayer;
    }

    // eslint-disable-next-line
    @action async fetchSubjectEnroll(subjectEnrollData): Promise<any> {
        const data = new FormData();
        data.append('cpf', subjectEnrollData.cpf.replace(/\D+/g, ''));
        data.append('selfie', subjectEnrollData.selfie, 'selfie.jpeg');
        data.append(
            subjectEnrollData.field,
            subjectEnrollData.identificationDoc,
            subjectEnrollData.identificationDoc.name,
        );
        const response = await this.transportLayer.enrollSubject(data);
        this.setData(subjectEnrollData);

        this.setEnrollResponse(response);
        return response;
    }

    // eslint-disable-next-line
    @action setData(formData): void {
        this.formData = formData;
    }

    // eslint-disable-next-line
    @action setSelfie(selfie): void {
        this.selfie = selfie;
    }

    // eslint-disable-next-line
    @action setDocPhoto(docPhoto): void {
        this.docPhoto = docPhoto;
    }

    // eslint-disable-next-line
    @action setEnrollResponse(enrollResponse): void {
        this.enrollResponse = enrollResponse;
    }
}
