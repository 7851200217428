import styled from 'styled-components';

interface ContainerProps {
    readonly mobile: boolean;
}

export const Container = styled.div<ContainerProps>`
    padding: ${props => (props.mobile ? '32px 16px' : '20px 0px 30px 0px')};

    display: flex;
    flex-direction: column;

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-form-item-label {
        padding-bottom: 2px;
    }

    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select-selector {
        border-radius: 2px !important;
    }
`;

export const Content = styled.div<ContainerProps>`
    display: flex;
    width: 50%;
    padding-bottom: 50px;
    flex-direction: ${props => (props.mobile ? 'column' : 'row')};
`;

export const DataContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: ${props => (props.mobile ? 'column' : 'row')};
`;

export const ResultContent = styled.div<ContainerProps>`
    display: flex;
    width:  ${props => (props.mobile ? '100%' : '50%')}
    padding-bottom: 50px;
    flex-direction: ${props => (props.mobile ? 'column' : 'row')};
    justify-content: space-between;
    align-items: center
}
`;

export const ResultData = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 330px;
}
`;

export const ImagePreview = styled.div`
    .ant-image {
        margin-top: 10px;
        margin-bottom: 10px;
        border-style: solid;
        border-color: #d5d5d5;
    }
`;

interface SectionProps {
    readonly mobile: boolean;
}

export const Section = styled.div<SectionProps>`
    margin-bottom: 28px;
    ${props => (props.mobile ? 'width:320px' : '')};
`;
