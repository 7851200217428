import UiStateStore from './uiStateStore';
import AuthStore from './authStore';
import SubjectValidationStore from './subjectValidationStore';
import SubjectValidationService from '../services/subjectValidationService';
import SubjectEnrollStore from './subjectEnrollStore';
import SubjectService from '../services/subjectService';
import SubjectSearchStore from './subjectSearchStore';
import SubjectDeleteStore from './subjectDeleteStore';
import SubjectVerifyStore from './subjectVerifyStore';
import SubjectIdentifyStore from './subjectIdentifyStore';
import SubjectVerifyWithDocStore from './subjectVerifyWithDocStore';
import ApplicationDeleteStore from './applicationDeleteStore';
import ApplicationListStore from './applicationListStore';
import ApplicationEnrollStore from './applicationEnrollStore';
import ApplicationService from '../services/applicationService';
import UserListStore from './userListStore';
import UserService from '../services/userService';

export default class RootStore {
    uiStateStore: UiStateStore;

    authStore: AuthStore;

    subjectValidationStore: SubjectValidationStore;

    subjectEnrollStore: SubjectEnrollStore;

    subjectSearchStore: SubjectSearchStore;

    subjectDeleteStore: SubjectDeleteStore;

    subjectVerifyStore: SubjectVerifyStore;

    subjectIdentifyStore: SubjectIdentifyStore;

    subjectVerifyWithDocStore: SubjectVerifyWithDocStore;

    applicationListStore: ApplicationListStore;

    applicationEnrollStore: ApplicationEnrollStore;

    applicationDeleteStore: ApplicationDeleteStore;

    userListStore: UserListStore;

    constructor() {
        this.uiStateStore = new UiStateStore(this);
        this.authStore = new AuthStore(this);
        this.subjectEnrollStore = new SubjectEnrollStore(this, SubjectService);
        this.subjectSearchStore = new SubjectSearchStore(this, SubjectService);
        this.subjectDeleteStore = new SubjectDeleteStore(this, SubjectService);
        this.subjectVerifyStore = new SubjectVerifyStore(this, SubjectService);
        this.subjectIdentifyStore = new SubjectIdentifyStore(
            this,
            SubjectService,
        );
        this.subjectVerifyWithDocStore = new SubjectVerifyWithDocStore(
            this,
            SubjectService,
        );
        this.subjectValidationStore = new SubjectValidationStore(
            this,
            SubjectValidationService,
        );
        this.applicationListStore = new ApplicationListStore(
            this,
            ApplicationService,
        );
        this.applicationDeleteStore = new ApplicationDeleteStore(
            this,
            ApplicationService,
        );
        this.applicationEnrollStore = new ApplicationEnrollStore(
            this,
            ApplicationService,
        );
        this.userListStore = new UserListStore(this, UserService);
    }
}
