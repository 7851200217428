import styled from 'styled-components';
import { Space, Card, Divider, Typography } from 'antd';

interface ContainerProps {
    readonly mobile: boolean;
}

export const Container = styled.div<ContainerProps>`
    padding: ${props => (props.mobile ? '32px 16px' : '20px 0px 30px 0px')};
    display: flex;
    flex-direction: ${props => (props.mobile ? 'column' : 'row')};

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-form-item-label {
        padding-bottom: 2px;
    }

    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select-selector {
        border-radius: 2px !important;
    }
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    padding-bottom: 50px;
    flex-direction: column;
`;

export const Section = styled.div<ContainerProps>`
    margin-bottom: 28px;
    ${props => (props.mobile ? 'width: 320px' : '')};
`;

export const SearchContainer = styled(Space)`
    width: 100%;
    margin-bottom: 16px;
`;

export const DetailIcon = styled.div`
    margin-right: 8px;
    color: #1890ff;
    font-size: 18px;
`;

export const DetailFieldContainer = styled.div`
    margin-bottom: 16px;
`;

export const DetailFieldContent = styled.div`
    flex: 1;
`;

export const DetailFieldValue = styled.div<{ sensitive?: boolean }>`
    padding: ${props => (props.sensitive ? '8px 12px' : '4px 0')};
    background: ${props => (props.sensitive ? '#f9f9f9' : 'transparent')};
    border-radius: ${props => (props.sensitive ? '4px' : '0')};
    border: ${props => (props.sensitive ? '1px solid #d9d9d9' : 'none')};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const DetailValueText = styled.span`
    word-break: break-all;
    max-width: 400px;
`;

export const DetailLoadingContainer = styled.div`
    text-align: center;
    padding: 20px;
`;

export const CardStyled = styled(Card)`
    box-shadow: none;
`;

export const DividerStyled = styled(Divider)`
    margin: 16px 0;
`;

export const SuccessText = styled(Typography.Text)`
    margin-left: 8px;
`;

export const VerticalSpace = styled(Space)`
    width: 100%;
    margin-bottom: 16px;
`;

export const DetailIconContainer = styled.div`
    display: flex;
    align-items: start;
    margin-top: 3px;
    margin-right: 8px;
    color: #1890ff;
    font-size: 18px;
`;

export const BooleanStatusIcon = styled(Space)``;

export const ModalBodyStyle = {
    padding: '12px 24px',
};

export const ModalWidth = 700;
