import api from './api';

interface User {
    id: string;
    name: string;
    email: string;
    cpf: string;
    roles: string[];
    application_id: number;
    created_at: string;
    updated_at: string;
}

interface ApiResponse {
    statusCode: number;
    message: string;
    users: User[];
}

interface PromoteUserParams {
    id: string;
}

interface DemoteUserParams {
    id: string;
}

interface DeleteUserParams {
    id: string;
}

const listUsers = async (): Promise<User[]> => {
    const { data } = await api.get<ApiResponse>('/api/v2/users');
    return data.users;
};

const promoteToAdmin = async (params: PromoteUserParams): Promise<void> => {
    await api.put(`/api/v2/users/${params.id}/promote`);
};

const demoteFromAdmin = async (params: DemoteUserParams): Promise<void> => {
    await api.put(`/api/v2/users/${params.id}/demote`);
};

const deleteUser = async (params: DeleteUserParams): Promise<void> => {
    await api.delete(`/api/v2/users/${params.id}`);
};

export default {
    listUsers,
    promoteToAdmin,
    demoteFromAdmin,
    deleteUser,
};

export type { User, PromoteUserParams, DemoteUserParams, DeleteUserParams };
