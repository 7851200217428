/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
    Typography,
    Form,
    Space,
    Button,
    message,
    Modal,
    Checkbox,
    Input,
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    ExclamationCircleOutlined,
    IdcardOutlined,
    AppstoreOutlined,
    KeyOutlined,
    SafetyCertificateOutlined,
    SecurityScanOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    EditOutlined,
    SaveOutlined,
    CloseOutlined,
    ReloadOutlined,
} from '@ant-design/icons';
import {
    DetailIconContainer,
    DetailFieldContainer,
    DetailFieldValue,
    DetailLoadingContainer,
    CardStyled,
    DividerStyled,
    SuccessText,
    BooleanStatusIcon,
    ModalBodyStyle,
    ModalWidth,
} from '../styles';

const { Text, Title } = Typography;

interface Application {
    id: string;
    name: string;
    client_id: string;
    client_secret: string;
    liveness_default: boolean;
}

interface ApplicationDetail extends Application {
    clientSecret?: string;
}

interface ApplicationDetailsProps {
    visible: boolean;
    application: ApplicationDetail | null;
    loading: boolean;
    onCancel: () => void;
    onRegenerateClientSecret: (id: string, name: string) => void;
    onUpdateApplication: (id: string, data: any) => Promise<void>;
    regeneratingSecret: boolean;
}

const ApplicationDetails: React.FC<ApplicationDetailsProps> = ({
    visible,
    application,
    loading,
    onCancel,
    onRegenerateClientSecret,
    onUpdateApplication,
    regeneratingSecret,
}) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [copiedField, setCopiedField] = useState<string | null>(null);
    const [editingField, setEditingField] = useState<string | null>(null);
    const [savingChanges, setSavingChanges] = useState(false);

    const handleSaveChanges = async () => {
        if (!application) return;

        const updatedData = {
            name: form.getFieldValue('name') || application.name,
            liveness_default:
                form.getFieldValue('liveness_default') !== undefined
                    ? form.getFieldValue('liveness_default')
                    : application.liveness_default,
        };

        setSavingChanges(true);
        try {
            await onUpdateApplication(application.id, updatedData);
            setEditingField(null);
        } catch (error) {
            // Error handling is managed by the parent component
        } finally {
            setSavingChanges(false);
        }
    };

    const cancelEditing = () => {
        if (application) {
            form.setFieldsValue({
                name: application.name,
                liveness_default: application.liveness_default,
            });
        }
        setEditingField(null);
    };

    const showRegenerateSecretConfirm = (id: string, name: string) => {
        Modal.confirm({
            title: formatMessage({
                id: 'app.application.redefine.confirm.title',
                defaultMessage:
                    'Tem certeza que deseja redefinir o Client Secret?',
            }),
            icon: <ExclamationCircleOutlined />,
            content: (
                <span>
                    {formatMessage(
                        {
                            id: 'app.application.redefine.confirm.content',
                            defaultMessage:
                                'O Client Secret da aplicação {name} será redefinido. Essa ação não pode ser desfeita e todas as aplicações que usam esse Client Secret precisarão ser atualizadas.',
                        },
                        { name },
                    )}
                </span>
            ),
            okText: formatMessage({
                id: 'app.confirm.dialog.yes',
                defaultMessage: 'Sim',
            }),
            okType: 'primary',
            cancelText: formatMessage({
                id: 'app.confirm.dialog.no',
                defaultMessage: 'Não',
            }),
            onOk() {
                onRegenerateClientSecret(id, name);
            },
        });
    };

    const renderDetailField = (
        icon: React.ReactNode,
        labelId: string,
        labelDefault: string,
        value: string | boolean,
        fieldKey: string,
        copyable = true,
        sensitive = false,
        truncate?: { maxLength: number; originalValue: string },
        editable = false,
    ) => {
        let stringValue = '';
        const isBooleanValue = typeof value === 'boolean';

        if (isBooleanValue) {
            stringValue = value ? 'Sim' : 'Não';
        } else {
            stringValue = String(value || '');

            if (
                truncate &&
                typeof value === 'string' &&
                value.length > truncate.maxLength
            ) {
                stringValue = `${String(value).substring(
                    0,
                    truncate.maxLength,
                )}...`;
            }
        }

        const isEditing = editingField === fieldKey;

        const renderEditableField = () => {
            if (isBooleanValue) {
                return (
                    <Form.Item name={fieldKey} noStyle valuePropName="checked">
                        <Checkbox>
                            <FormattedMessage
                                id="app.application.list.table.liveness_default.checkbox"
                                defaultMessage="Ativado"
                            />
                        </Checkbox>
                    </Form.Item>
                );
            }

            return (
                <Form.Item
                    name={fieldKey}
                    noStyle
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'app.application.update.field.required',
                                defaultMessage: 'Este campo é obrigatório.',
                            }),
                        },
                    ]}
                >
                    <Input style={{ width: '100%' }} />
                </Form.Item>
            );
        };

        const renderEditButtons = () => {
            if (isEditing) {
                return (
                    <div style={{ display: 'flex' }}>
                        <Button
                            type="text"
                            icon={<SaveOutlined />}
                            onClick={handleSaveChanges}
                            loading={savingChanges}
                            title={formatMessage({
                                id: 'app.application.update.save',
                                defaultMessage: 'Salvar alterações',
                            })}
                        />
                        <Button
                            type="text"
                            danger
                            icon={<CloseOutlined />}
                            onClick={cancelEditing}
                            title={formatMessage({
                                id: 'app.application.update.cancel',
                                defaultMessage: 'Cancelar edição',
                            })}
                        />
                    </div>
                );
            }

            return editable ? (
                <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => setEditingField(fieldKey)}
                    title={formatMessage({
                        id: 'app.application.update.edit',
                        defaultMessage: 'Editar',
                    })}
                />
            ) : null;
        };

        const renderRegenerateButton = () => {
            if (fieldKey === 'client_secret' && application) {
                return (
                    <Button
                        type="text"
                        icon={<ReloadOutlined />}
                        onClick={() =>
                            showRegenerateSecretConfirm(
                                application.id,
                                application.name,
                            )
                        }
                        loading={regeneratingSecret}
                        title={formatMessage({
                            id: 'app.application.redefine.secret',
                            defaultMessage: 'Redefinir Client Secret',
                        })}
                    />
                );
            }
            return null;
        };

        const renderBooleanField = () => (
            <BooleanStatusIcon>
                {value ? (
                    <CheckCircleFilled style={{ color: '#52c41a' }} />
                ) : (
                    <CloseCircleFilled style={{ color: '#ff4d4f' }} />
                )}
                <Text>{stringValue}</Text>
            </BooleanStatusIcon>
        );

        const renderTextField = () => (
            <Text
                style={{
                    wordBreak: 'break-all',
                    maxWidth: '400px',
                }}
                copyable={
                    copyable && !isEditing
                        ? {
                              text: truncate?.originalValue || stringValue,
                              onCopy: () => {
                                  setCopiedField(fieldKey);
                                  setTimeout(() => setCopiedField(null), 3000);
                                  message.success(
                                      formatMessage({
                                          id: 'app.application.copy.success',
                                          defaultMessage:
                                              'Copiado para a área de transferência!',
                                      }),
                                  );
                              },
                          }
                        : false
                }
            >
                {stringValue || '-'}
            </Text>
        );

        const renderField = () => {
            if (isEditing) {
                return renderEditableField();
            }

            if (isBooleanValue) {
                return renderBooleanField();
            }

            return renderTextField();
        };

        return (
            <DetailFieldContainer>
                <div style={{ display: 'flex' }}>
                    <DetailIconContainer>{icon}</DetailIconContainer>
                    <div style={{ flex: 1 }}>
                        <Space direction="vertical">
                            <Text strong>
                                <FormattedMessage
                                    id={labelId}
                                    defaultMessage={labelDefault}
                                />
                                :
                            </Text>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <DetailFieldValue sensitive={sensitive}>
                                    {renderField()}
                                    {copyable && !isBooleanValue && !isEditing && (
                                        <>
                                            {copiedField === fieldKey && (
                                                <SuccessText type="success">
                                                    <FormattedMessage
                                                        id="app.application.copied"
                                                        defaultMessage="Copiado!"
                                                    />
                                                </SuccessText>
                                            )}
                                        </>
                                    )}
                                    {renderEditButtons()}
                                </DetailFieldValue>
                                {renderRegenerateButton()}
                            </div>
                        </Space>
                    </div>
                </div>
            </DetailFieldContainer>
        );
    };

    return (
        <Modal
            title={
                <Space>
                    <AppstoreOutlined />
                    <FormattedMessage
                        id="app.application.detail.title"
                        defaultMessage="Detalhes da Aplicação"
                    />
                </Space>
            }
            visible={visible}
            onCancel={() => {
                onCancel();
                setEditingField(null);
            }}
            footer={[
                <Button
                    key="close"
                    onClick={() => {
                        onCancel();
                        setEditingField(null);
                    }}
                >
                    <FormattedMessage
                        id="app.application.detail.close"
                        defaultMessage="Fechar"
                    />
                </Button>,
            ]}
            width={ModalWidth}
            bodyStyle={ModalBodyStyle}
        >
            {application && (
                <CardStyled bordered={false}>
                    {loading ? (
                        <DetailLoadingContainer>
                            <Text type="secondary">
                                <FormattedMessage
                                    id="app.application.detail.loading"
                                    defaultMessage="Carregando..."
                                />
                            </Text>
                        </DetailLoadingContainer>
                    ) : (
                        <Form
                            form={form}
                            initialValues={{
                                name: application.name,
                                liveness_default: application.liveness_default,
                            }}
                        >
                            <Title level={5}>
                                <FormattedMessage
                                    id="app.application.detail.basic.info"
                                    defaultMessage="Informações Básicas"
                                />
                            </Title>

                            {renderDetailField(
                                <IdcardOutlined />,
                                'app.application.list.table.id',
                                'ID',
                                application.id,
                                'id',
                                false,
                            )}

                            {renderDetailField(
                                <AppstoreOutlined />,
                                'app.application.list.table.name',
                                'Nome',
                                application.name,
                                'name',
                                false,
                                false,
                                undefined,
                                true,
                            )}

                            <DividerStyled />

                            <Title level={5}>
                                <FormattedMessage
                                    id="app.application.detail.security.info"
                                    defaultMessage="Informações de Segurança"
                                />
                            </Title>

                            {renderDetailField(
                                <KeyOutlined />,
                                'app.application.list.table.client_id',
                                'Client ID',
                                application.client_id,
                                'client_id',
                                true,
                                true,
                            )}

                            {renderDetailField(
                                <SafetyCertificateOutlined />,
                                'app.application.detail.client_secret',
                                'Client Secret',
                                application.clientSecret || '-',
                                'client_secret',
                                !!application.clientSecret,
                                true,
                                application.clientSecret
                                    ? {
                                          maxLength:
                                              application.client_id.length,
                                          originalValue:
                                              application.clientSecret,
                                      }
                                    : undefined,
                            )}

                            <DividerStyled />

                            {renderDetailField(
                                <SecurityScanOutlined />,
                                'app.application.list.table.liveness_default',
                                'Liveness Default',
                                application.liveness_default,
                                'liveness_default',
                                false,
                                false,
                                undefined,
                                true,
                            )}
                        </Form>
                    )}
                </CardStyled>
            )}
        </Modal>
    );
};

export default ApplicationDetails;
