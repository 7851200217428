/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable, makeObservable } from 'mobx';

export default class ApplicationEnrollStore {
    @observable formData;

    @observable enrollResponse;

    rootStore;

    transportLayer;

    // eslint-disable-next-line
    constructor(rootStore, transportLayer) {
        this.rootStore = rootStore;
        makeObservable(this);
        this.transportLayer = transportLayer;
    }

    // eslint-disable-next-line
    @action async fetchApplicationEnroll(value: { name: string; liveness_default: boolean }): Promise<any> {
        const response = await this.transportLayer.enrollApplication({
            name: value.name,
            liveness_default: value.liveness_default,
        });
        this.setEnrollResponse(response);
        return response;
    }

    // eslint-disable-next-line
    @action setEnrollResponse(enrollResponse): void {
        this.enrollResponse = enrollResponse;
    }
}
