import jwtDecode from 'jwt-decode';
import { JWT } from '../interfaces/JWT';
import api from '../services/api';

// Define custom LocationState type based on how it's used
interface LocationState {
    lastUrl?: string;
    [key: string]: any;
}

interface TokenValidationResult {
    isValid: boolean;
    user: string | null;
    token: string | null;
}

export const validateToken = (): TokenValidationResult => {
    let token = localStorage.getItem('access_token');
    let user = localStorage.getItem('user');

    if (user && token) {
        const dateNow = new Date();
        const decodedToken: JWT = jwtDecode(token);

        if (decodedToken && decodedToken.exp < dateNow.getTime() / 1000) {
            token = null;
            user = null;
            localStorage.clear();
        } else {
            api.defaults.headers.common.Authorization = `Bearer ${token}`;
        }
    }

    if (user && !token) {
        user = null;
        localStorage.clear();
    }

    return {
        isValid: !!user && !!token,
        user,
        token,
    };
};

export const getRedirectPath = (
    pathname: string,
    search: string,
    state?: LocationState,
): string => {
    if (state && 'lastUrl' in state) {
        return state.lastUrl as string;
    }
    return pathname + search;
};
