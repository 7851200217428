import React, { useState } from 'react';
import {
    Form,
    Input,
    message,
    List,
    Typography,
    Card,
    Checkbox,
    Grid,
} from 'antd';
// eslint-disable-next-line import/no-unresolved
import { useStore } from 'hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Container,
    Content,
    Section,
    ResultContent,
    FormRow,
    NameInput,
} from './styles';
import Footer from '../Footer';
import { SectionTitle } from '../../Settings/Profile/styles';

const { Title, Text } = Typography;

const { useBreakpoint } = Grid;

interface EnrollResponse {
    id: string;
    name: string;
    client_id: string;
    client_secret: string;
    liveness_default: boolean;
}

interface EnrollRequest {
    name: string;
    liveness_default: boolean;
}

const ApplicationEnrollForm = (): React.ReactElement => {
    const { store } = useStore();
    const screen = useBreakpoint();
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [enrollResponse, setEnrollResponse] = useState<EnrollResponse | null>(
        null,
    );

    const handleEnrollApplication = async (value: EnrollRequest) => {
        setLoading(true);
        try {
            // eslint-disable-next-line
            const response = await store.applicationEnrollStore.fetchApplicationEnroll({
                    name: value.name,
                    liveness_default: value.liveness_default,
                });
            setEnrollResponse(response);
            message.success(
                formatMessage({
                    id: 'app.application.enroll.form.success',
                    defaultMessage: 'Aplicação cadastrada com sucesso!',
                }),
            );
        } catch (error) {
            message.error(
                formatMessage({
                    id: 'app.application.enroll.form.error',
                    defaultMessage: 'Erro ao cadastrar aplicação.',
                }),
            );
        } finally {
            setLoading(false);
        }
    };

    const handleClear = () => {
        form.resetFields();
        setEnrollResponse(null);
    };

    return (
        <Container mobile={false}>
            <Content>
                <Section mobile={false} style={{ marginTop: 8 }}>
                    <SectionTitle>
                        <FormattedMessage
                            id="app.application.enroll.form.title"
                            defaultMessage="Cadastrar Aplicação"
                        />
                    </SectionTitle>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleEnrollApplication}
                    >
                        <FormRow mobile={!screen.md}>
                            <NameInput
                                name="name"
                                label={
                                    <FormattedMessage
                                        id="app.application.enroll.name"
                                        defaultMessage="Nome da Aplicação"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: formatMessage({
                                            id: 'app.application.enroll.name.required',
                                            defaultMessage:
                                                'Por favor, insira o nome da aplicação.',
                                        }),
                                    },
                                ]}
                            >
                                <Input placeholder="Ex: My Application" />
                            </NameInput>
                            <Form.Item
                                name="liveness_default"
                                valuePropName="checked"
                                initialValue={false}
                            >
                                <Checkbox>
                                    <FormattedMessage
                                        id="app.application.enroll.liveness_default"
                                        defaultMessage="Liveness Default"
                                    />
                                </Checkbox>
                            </Form.Item>
                        </FormRow>
                    </Form>
                </Section>
                {enrollResponse && (
                    <ResultContent>
                        <Section mobile={false}>
                            <Title level={4}>
                                <FormattedMessage
                                    id="app.application.enroll.result.title"
                                    defaultMessage="Credenciais da Aplicação"
                                />
                            </Title>
                            <Card>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={[
                                        {
                                            label: formatMessage({
                                                id: 'app.application.enroll.result.name',
                                                defaultMessage:
                                                    'Nome da Aplicação',
                                            }),
                                            value: enrollResponse.name,
                                        },
                                        {
                                            label: formatMessage({
                                                id: 'app.application.enroll.result.client_id',
                                                defaultMessage: 'Client ID',
                                            }),
                                            value: enrollResponse.client_id,
                                        },
                                        {
                                            label: formatMessage({
                                                id: 'app.application.enroll.result.client_secret',
                                                defaultMessage: 'Client Secret',
                                            }),
                                            value: enrollResponse.client_secret,
                                        },
                                        {
                                            label: formatMessage({
                                                id: 'app.application.enroll.result.liveness_default',
                                                defaultMessage:
                                                    'Liveness Default',
                                            }),
                                            value: enrollResponse.liveness_default
                                                ? 'Sim'
                                                : 'Não',
                                        },
                                    ]}
                                    renderItem={item => (
                                        <List.Item>
                                            <Text strong>{item.label}:</Text>{' '}
                                            <Text>{item.value}</Text>
                                        </List.Item>
                                    )}
                                />
                            </Card>
                        </Section>
                    </ResultContent>
                )}
            </Content>
            <Footer
                onSend={() => form.submit()}
                loading={loading}
                onClear={handleClear}
            />
        </Container>
    );
};

export default ApplicationEnrollForm;
