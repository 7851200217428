import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, message, Modal, Space, Input } from 'antd';
import { useStore } from 'hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    ExclamationCircleOutlined,
    SearchOutlined,
    DeleteOutlined,
    EyeOutlined,
} from '@ant-design/icons';
import { Container, Content, Section, VerticalSpace } from './styles';
import { SectionTitle } from '../../Settings/Profile/styles';
import ListFooter from '../ListFooter';
import ApplicationDetails from './components/ApplicationDetails';

interface Application {
    id: string;
    name: string;
    client_id: string;
    client_secret: string;
    liveness_default: boolean;
}

interface ApplicationDetail extends Application {
    clientSecret?: string;
}

const ApplicationListForm = (): React.ReactElement => {
    const { store } = useStore();
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [detailLoading, setDetailLoading] = useState(false);
    const [applications, setApplications] = useState<Application[]>([]);
    const [filteredApplications, setFilteredApplications] = useState<
        Application[]
    >([]);
    const [searchText, setSearchText] = useState('');
    const [detailModalVisible, setDetailModalVisible] = useState(false);
    const [selectedApplication, setSelectedApplication] =
        useState<ApplicationDetail | null>(null);
    const [regeneratingSecret, setRegeneratingSecret] = useState(false);

    const fetchApplications = useCallback(async () => {
        setLoading(true);
        try {
            const response: Application[] =
                await store.applicationListStore.listApplications();
            setApplications(response || []);
            setFilteredApplications(response || []);
        } catch (error) {
            message.error(
                formatMessage({
                    id: 'app.application.list.error',
                    defaultMessage: 'Erro ao carregar aplicações.',
                }),
            );
        } finally {
            setLoading(false);
        }
    }, [store, formatMessage]);

    useEffect(() => {
        fetchApplications();
    }, [fetchApplications]);

    useEffect(() => {
        const filtered = applications.filter(
            app =>
                app.name.toLowerCase().includes(searchText.toLowerCase()) ||
                app.client_id.toLowerCase().includes(searchText.toLowerCase()),
        );
        setFilteredApplications(filtered);
    }, [searchText, applications]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const handleDelete = async (id: string) => {
        try {
            await store.applicationDeleteStore.deleteApplication({ id });
            message.success(
                formatMessage({
                    id: 'app.application.delete.success',
                    defaultMessage: 'Aplicação excluída com sucesso.',
                }),
            );
            fetchApplications();
        } catch (error) {
            message.error(
                formatMessage({
                    id: 'app.application.delete.error',
                    defaultMessage: 'Erro ao excluir aplicação.',
                }),
            );
        }
    };

    const showDeleteConfirm = (id: string, name: string) => {
        Modal.confirm({
            title: formatMessage({
                id: 'app.application.delete.confirm.title',
                defaultMessage: 'Tem certeza que deseja excluir a aplicação?',
            }),
            icon: <ExclamationCircleOutlined />,
            content: (
                <span>
                    {formatMessage(
                        {
                            id: 'app.application.delete.confirm.content',
                            defaultMessage:
                                'A aplicação {name} (ID: {id}) será excluída permanentemente.',
                        },
                        { name, id },
                    )}
                </span>
            ),
            okText: formatMessage({
                id: 'app.confirm.dialog.yes',
                defaultMessage: 'Sim',
            }),
            okType: 'danger',
            cancelText: formatMessage({
                id: 'app.confirm.dialog.no',
                defaultMessage: 'Não',
            }),
            onOk() {
                handleDelete(id);
            },
        });
    };

    const handleRegenerateClientSecret = async (id: string) => {
        setRegeneratingSecret(true);
        try {
            const response =
                await store.applicationListStore.regenerateClientSecret(id);
            const newClientSecret = response;
            if (selectedApplication) {
                setSelectedApplication({
                    ...selectedApplication,
                    clientSecret: newClientSecret,
                });
            }

            message.success(
                formatMessage({
                    id: 'app.application.redefine.success',
                    defaultMessage: 'Client Secret redefinido com sucesso.',
                }),
            );

            fetchApplications();
        } catch (error) {
            message.error(
                formatMessage({
                    id: 'app.application.redefine.error',
                    defaultMessage: 'Erro ao redefinir Client Secret.',
                }),
            );
        } finally {
            setRegeneratingSecret(false);
        }
    };

    const handleUpdateApplication = async (id: string, updatedData: any) => {
        try {
            await store.applicationListStore.updateApplication(id, updatedData);

            setSelectedApplication(prev => {
                if (!prev) return null;
                return {
                    ...prev,
                    name: updatedData.name,
                    liveness_default: updatedData.liveness_default,
                };
            });

            message.success(
                formatMessage({
                    id: 'app.application.update.success',
                    defaultMessage: 'Aplicação atualizada com sucesso.',
                }),
            );

            fetchApplications();
            return Promise.resolve();
        } catch (error) {
            message.error(
                formatMessage({
                    id: 'app.application.update.error',
                    defaultMessage: 'Erro ao atualizar aplicação.',
                }),
            );
            return Promise.reject(error);
        }
    };

    const fetchApplicationDetails = async (clientId: string) => {
        setDetailLoading(true);
        try {
            const response = await store.applicationListStore.getClientSecret(
                clientId,
            );
            return response;
        } catch (error) {
            message.error(
                formatMessage({
                    id: 'app.application.get.client.secret.error',
                    defaultMessage: 'Erro ao obter detalhes da aplicação.',
                }),
            );
            return null;
        } finally {
            setDetailLoading(false);
        }
    };

    const showApplicationDetails = async (application: Application) => {
        setSelectedApplication(application);
        setDetailModalVisible(true);

        const clientSecret = await fetchApplicationDetails(
            application.client_id,
        );
        if (clientSecret) {
            setSelectedApplication(prevState =>
                prevState ? { ...prevState, clientSecret } : null,
            );
        }
    };

    const columns = [
        {
            title: (
                <FormattedMessage
                    id="app.application.list.table.id"
                    defaultMessage="ID"
                />
            ),
            dataIndex: 'id',
            key: 'id',
            sorter: {
                compare: (a: Application, b: Application) => {
                    if (!a.id || !b.id) return 0;
                    return a.id.toString().localeCompare(b.id.toString());
                },
                multiple: 1,
            },
            defaultSortOrder: 'ascend' as const,
        },
        {
            title: (
                <FormattedMessage
                    id="app.application.list.table.name"
                    defaultMessage="Nome"
                />
            ),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: (
                <FormattedMessage
                    id="app.application.list.table.client_id"
                    defaultMessage="Client ID"
                />
            ),
            dataIndex: 'client_id',
            key: 'client_id',
        },
        {
            title: (
                <FormattedMessage
                    id="app.application.list.table.liveness_default"
                    defaultMessage="Liveness Default"
                />
            ),
            dataIndex: 'liveness_default',
            key: 'liveness_default',
            render: (value: boolean) => (value ? 'Sim' : 'Não'),
        },
        {
            title: (
                <FormattedMessage
                    id="app.application.list.table.actions"
                    defaultMessage="Ações"
                />
            ),
            key: 'actions',
            render: (_: any, record: Application) => (
                <Space>
                    <Button
                        type="text"
                        icon={<EyeOutlined />}
                        onClick={() => showApplicationDetails(record)}
                        title={formatMessage({
                            id: 'app.application.list.table.view',
                            defaultMessage: 'Visualizar detalhes',
                        })}
                    />
                    <Button
                        type="text"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() =>
                            showDeleteConfirm(record.id, record.name)
                        }
                        title={formatMessage({
                            id: 'app.application.list.table.delete',
                            defaultMessage: 'Excluir',
                        })}
                    />
                </Space>
            ),
        },
    ];

    return (
        <Container mobile={false}>
            <Content>
                <Section mobile={false} style={{ marginTop: 8 }}>
                    <SectionTitle>
                        <FormattedMessage
                            id="app.application.list.table.title"
                            defaultMessage="Lista de Aplicações"
                        />
                    </SectionTitle>
                    <VerticalSpace direction="vertical" size="middle">
                        <Input
                            prefix={<SearchOutlined />}
                            placeholder={formatMessage({
                                id: 'app.application.list.search.placeholder',
                                defaultMessage: 'Buscar por nome ou Client ID',
                            })}
                            onChange={handleSearch}
                            value={searchText}
                            allowClear
                            style={{ width: '320px' }}
                        />
                        <Table
                            dataSource={filteredApplications}
                            columns={columns}
                            loading={loading}
                            rowKey="id"
                            pagination={{
                                pageSize: 10,
                                showTotal: (total, range) =>
                                    `${range[0]}-${range[1]} de ${total} aplicações.`,
                            }}
                            sortDirections={['ascend', 'descend']}
                        />
                    </VerticalSpace>
                </Section>
            </Content>
            <ListFooter onRefresh={fetchApplications} loading={loading} />

            <ApplicationDetails
                visible={detailModalVisible}
                application={selectedApplication}
                loading={detailLoading}
                onCancel={() => setDetailModalVisible(false)}
                onRegenerateClientSecret={handleRegenerateClientSecret}
                onUpdateApplication={handleUpdateApplication}
                regeneratingSecret={regeneratingSecret}
            />
        </Container>
    );
};

export default ApplicationListForm;
