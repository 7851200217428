/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

interface ContainerProps {
    readonly mobile: boolean;
}

export const Container = styled.div<ContainerProps>`
    padding: ${props => (props.mobile ? '32px 16px' : '40px 48px 30px 48px')};

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-form-item-label {
        padding-bottom: 2px;
    }

    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select-selector {
        border-radius: 2px !important;
    }

    .ant-tabs-tabpane {
        padding-top: 0;
    }
`;
