import React from 'react';
import { SendOutlined, ClearOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { StyledFooter, StyledDesktopButtons } from './styles';

interface FooterProps {
    onSend: () => void;
    onClear: () => void;
    loading: boolean;
}

const ApplicationFooter = ({
    onSend,
    onClear,
    loading,
}: FooterProps): React.ReactElement => {
    return (
        <StyledFooter>
            <StyledDesktopButtons>
                <Button icon={<ClearOutlined />} onClick={onClear}>
                    <span>
                        <FormattedMessage
                            id="app.application.footer.button.clear"
                            defaultMessage="Limpar"
                        />
                    </span>
                </Button>
                <Button
                    type="primary"
                    icon={<SendOutlined />}
                    style={{ marginLeft: 8 }}
                    onClick={onSend}
                    loading={loading}
                >
                    <span>
                        <FormattedMessage
                            id="app.application.footer.button.send"
                            defaultMessage="Enviar"
                        />
                    </span>
                </Button>
            </StyledDesktopButtons>
        </StyledFooter>
    );
};

export default ApplicationFooter;
