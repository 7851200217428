import api from './api';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function listApplications(): Promise<any> {
    const response = await api.get(`/api/v2/applications`);
    const result = response.data;
    return result;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function deleteApplication(data: any): Promise<any> {
    const response = await api.delete(`/api/v2/applications/${data.id}`);
    const result = response.data;
    return result;
}

// eslint-disable-next-line
async function enrollApplication(data: FormData): Promise<any> {
    const response = await api.post('/api/v2/applications', data, {
        headers: { 'Content-Type': 'application/json' },
    });
    const result = response.data;
    return result;
}

// eslint-disable-next-line
async function getClientSecret(clientId: any): Promise<any> {
    // eslint-disable-next-line
    const response = await api.get(`/api/v2/applications/client-secret/${clientId}`);
    const result = response.data;
    return result;
}

async function regenerateClientSecret(id: string): Promise<any> {
    // eslint-disable-next-line
    const response = await api.put(`/api/v2/applications/${id}/regenerate-secret`);
    const result = response.data;
    return result;
}

// eslint-disable-next-line
async function updateApplication(id: string, data: { name: string; liveness_default: boolean }): Promise<any> {
    const response = await api.put(`/api/v2/applications/${id}`, data);
    const result = response.data;
    return result;
}

export default {
    listApplications,
    deleteApplication,
    enrollApplication,
    getClientSecret,
    regenerateClientSecret,
    updateApplication,
};
