import jwt_decode from 'jwt-decode';
import { JWT } from '../interfaces/JWT';

const isAdmin = (): boolean => {
    const token = localStorage.getItem('access_token');
    if (!token) {
        return false;
    }

    try {
        const decoded = jwt_decode<JWT>(token);
        return decoded.roles?.includes('admin') || false;
    } catch (error) {
        return false;
    }
};

export default isAdmin;
