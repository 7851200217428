import styled from 'styled-components';

interface ContainerProps {
    readonly mobile: boolean;
}

export const Container = styled.div<ContainerProps>`
    padding: ${props => (props.mobile ? '32px 16px' : '20px 0px 30px 0px')};

    display: flex;

    flex-direction: ${props => (props.mobile ? 'column' : 'row')};

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-form-item-label {
        padding-bottom: 2px;
    }

    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select-selector {
        border-radius: 2px !important;
    }
`;

export const Content = styled.div`
    display: flex;
    width: 50%;
    padding-bottom: 50px;
`;

export const ResultContent = styled.div`
    display: flex;
    width: 50%;
    padding-bottom: 50px;
    flex-direction: column;
}
`;

export const ResultData = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: left;
}
`;

export const ImagePreview = styled.div`
    .ant-image {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`;

export const InputContainer = styled.div`
    width: 320px;
`;

interface SectionProps {
    readonly mobile: boolean;
}

export const Section = styled.div<SectionProps>`
    margin-bottom: 28px;
    ${props => (props.mobile ? 'width:320px' : '')};
`;
