import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
});

api.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            const newConfig = { ...config };
            newConfig.headers = newConfig.headers || {};
            newConfig.headers.Authorization = `Bearer ${token}`;
            return newConfig;
        }
        return config;
    },
    error => Promise.reject(error),
);

export default api;
