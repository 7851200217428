/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, makeObservable, observable } from 'mobx';

interface IApplication {
    id: string;
    name: string;
    client_id: string;
    client_secret: string;
    liveness_default: boolean;
}

interface IApplicationUpdate {
    name: string;
    liveness_default: boolean;
}

export default class ApplicationListStore {
    @observable applications = [] as IApplication[];

    rootStore;

    transportLayer;

    // eslint-disable-next-line
    constructor(rootStore, transportLayer) {
        this.rootStore = rootStore;
        makeObservable(this);
        this.transportLayer = transportLayer;
    }

    @action
    async listApplications(): Promise<IApplication[]> {
        const response = await this.transportLayer.listApplications();
        // eslint-disable-next-line
        const applications = Array.isArray(response?.applications) ? response.applications : [];
        this.setApplications(applications);
        return applications;
    }

    // eslint-disable-next-line
    @action async deleteApplication(id): Promise<void> {
        await this.transportLayer.deleteApplication(id);
        await this.listApplications();
    }

    @action private setApplications(applications: IApplication[]): void {
        this.applications = applications;
    }

    // eslint-disable-next-line
    @action async getClientSecret(clientId): Promise<string> {
        const response = await this.transportLayer.getClientSecret(clientId);
        return response.client_secret;
    }

    // eslint-disable-next-line
    @action async updateApplication (id: string, data: IApplicationUpdate): Promise<void> {
        await this.transportLayer.updateApplication(id, data);
        await this.listApplications();
    }

    @action async regenerateClientSecret(id: string): Promise<string> {
        const response = await this.transportLayer.regenerateClientSecret(id);
        await this.listApplications();
        return response.client_secret;
    }
}
