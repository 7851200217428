import React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { StyledFooter, StyledDesktopButtons } from './styles';

interface ListFooterProps {
    onRefresh: () => void;
    loading: boolean;
}

const ApplicationListFooter = ({
    onRefresh,
    loading,
}: ListFooterProps): React.ReactElement => {
    return (
        <StyledFooter>
            <StyledDesktopButtons>
                <Button
                    type="primary"
                    icon={<SyncOutlined />}
                    onClick={onRefresh}
                    loading={loading}
                >
                    <span>
                        <FormattedMessage
                            id="app.application.list.footer.button.refresh"
                            defaultMessage="Atualizar"
                        />
                    </span>
                </Button>
            </StyledDesktopButtons>
        </StyledFooter>
    );
};

export default ApplicationListFooter;
