import { makeAutoObservable } from 'mobx';
import RootStore from '.';
import {
    User,
    PromoteUserParams,
    DemoteUserParams,
    DeleteUserParams,
} from '../services/userService';

interface UserService {
    listUsers(): Promise<User[]>;
    promoteToAdmin(params: PromoteUserParams): Promise<void>;
    demoteFromAdmin(params: DemoteUserParams): Promise<void>;
    deleteUser(params: DeleteUserParams): Promise<void>;
}

export default class UserListStore {
    rootStore: RootStore;

    service: UserService;

    constructor(rootStore: RootStore, service: UserService) {
        this.rootStore = rootStore;
        this.service = service;
        makeAutoObservable(this, { rootStore: false, service: false });
    }

    async listUsers(): Promise<User[]> {
        return this.service.listUsers();
    }

    async promoteToAdmin(params: PromoteUserParams): Promise<void> {
        return this.service.promoteToAdmin(params);
    }

    async demoteFromAdmin(params: DemoteUserParams): Promise<void> {
        return this.service.demoteFromAdmin(params);
    }

    async deleteUser(params: DeleteUserParams): Promise<void> {
        return this.service.deleteUser(params);
    }
}
