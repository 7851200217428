/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable, makeObservable } from 'mobx';

export default class ApplicationDeleteStore {
    @observable deleteResponse;

    rootStore;

    transportLayer;

    // eslint-disable-next-line
    constructor(rootStore, transportLayer) {
        this.rootStore = rootStore;
        makeObservable(this);
        this.transportLayer = transportLayer;
    }

    // eslint-disable-next-line
    @action async deleteApplication(id): Promise<void> {
        await this.transportLayer.deleteApplication(id);
    }

    // eslint-disable-next-line
    @action setDeleteResponse(deleteResponse): void {
        this.deleteResponse = deleteResponse;
    }
}
