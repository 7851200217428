import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Tabs, Grid } from 'antd';
import {
    PlusOutlined,
    DeleteOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import ApplicationListForm from './ListForm';
import ApplicationCreateForm from './EnrollForm';
import ApplicationDeleteForm from './DeleteForm';
import { Container } from './styles';

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

const Application = (): React.ReactElement => {
    const screen = useBreakpoint();
    const location = useLocation();
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState('#list');

    useEffect(() => {
        if (location.hash) {
            setSelectedTab(location.hash);
        } else {
            const hash = '#list';
            setSelectedTab(hash);
            history.replace(hash);
        }
    }, [history, location.hash]);

    const handleTabChange = (key: string) => {
        setSelectedTab(key);
        history.push(key);
    };

    return (
        <Container mobile={!screen.md}>
            <Tabs activeKey={selectedTab} onChange={handleTabChange}>
                <TabPane
                    tab={
                        <>
                            <UnorderedListOutlined />
                            <FormattedMessage
                                id="app.application.list.tab"
                                defaultMessage="Listar"
                            />
                        </>
                    }
                    key="#list"
                    style={{
                        padding: !screen.md ? '32px 20px 40px 20px' : '0px',
                    }}
                >
                    <ApplicationListForm />
                </TabPane>
                <TabPane
                    tab={
                        <>
                            <PlusOutlined />
                            <FormattedMessage
                                id="app.application.enroll.tab"
                                defaultMessage="Criar"
                            />
                        </>
                    }
                    key="#create"
                    style={{
                        padding: !screen.md ? '32px 20px 40px 20px' : '0px',
                    }}
                >
                    <ApplicationCreateForm />
                </TabPane>
                <TabPane
                    tab={
                        <>
                            <DeleteOutlined />
                            <FormattedMessage
                                id="app.application.delete.tab"
                                defaultMessage="Excluir"
                            />
                        </>
                    }
                    key="#delete"
                    style={{
                        padding: !screen.md ? '32px 20px 40px 20px' : '0px',
                    }}
                >
                    <ApplicationDeleteForm />
                </TabPane>
            </Tabs>
        </Container>
    );
};

export default Application;
